import KorekSignup from "../Pages/SignUp/korek/korek-signup";
import KsaSignup from "../Pages/SignUp/KsaSignup/KsaSignup";
import ZainSignup from "../Pages/SignUp/zain-iraq/ZainSignup";
import DzoSignin from "../Pages/SignIn/DzoSignin/DzoSignin";
import EtuSignin from "../Pages/SignIn/EtuSignin/EtuSignin";
import KorekSignin from "../Pages/SignIn/KorekSignin/KorekSignin";
import KsaSignin from "../Pages/SignIn/KsaSignin/KsaSignin";
import ZainSignin from "../Pages/SignIn/ZainSignin/ZainSignin";
// import EtisalatSignUp from "../Pages/SignUp/EtisalatSignUp";
import SignupPage from "../Pages/SignUp/SignUp";
import SuccessKorek from "../Pages/SignUp/korek/korek-success";
import ZainThankyouPage from "../Pages/SignUp/zain-iraq/ThankyouPage";
import Success from "../Pages/Success/Success";
import KsaSuccess from "../Pages/SignUp/KsaSignup/KsaSuccess";
import EtisalatSignUp from "../Pages/SignUp/EtisalatSignUp";

// import IraqSignup from "../pages/SignUp/IraqSignUp";
const hostNamesInfo = [
  {
    hostName: "dzo.gamerclub.live",
    theme: "iraq",
  },
  {
    hostName: "localhost",
    theme: "iraq",
    // theme: "iraq",
  },
];
export const getHostComponentByRoute = (route) => {
  const hostname = document.location.host;
  if (route === "signup") {
    switch (hostname) {
      // Algeria
      case "dzo.gamerclub.live": // terminated, old design
        return <SignupPage />;
      case "etuae.gamerclub.live": // will be refactored -- vas1, old design
        return <EtisalatSignUp />;
      case "ziq.gamerclub.live": //worlinks new design
        return <ZainSignup />;
      case "korek.gamerclub.live": //worlinks new design
        return <KorekSignup />;
      case "ksa.gamerclub.live": //worlinks new design
        return <KsaSignup />;
      default:
        return <KorekSignup />;
    }
  }
};
export const getHostSuccessComponentByRoute = (route) => {
  const hostname = document.location.host;
  if (route === "success") {
    switch (hostname) {
      case "ziq.gamerclub.live": //worlinks new design
        return <ZainThankyouPage />;
      case "korek.gamerclub.live": //worlinks new design
        return <SuccessKorek />;
      case "ksa.gamerclub.live": //worlinks new design
        return <KsaSuccess />;
        
      default:
        return <Success />;
    }
  }
};
export const getHostSigninComponentByRoute = (route) => {
  const hostname = document.location.host;
  if (route === "signIn") {
    switch (hostname) {
      // Algeria
      case "dzo.gamerclub.live": // terminated, old design
        return <DzoSignin />;
      case "etuae.gamerclub.live": // will be refactored -- vas1, old design
        return <EtuSignin />;
      case "ziq.gamerclub.live": //worlinks new design
        return <ZainSignin />;
      case "korek.gamerclub.live": //worlinks new design
        return <KorekSignin />;
      case "ksa.gamerclub.live": //worlinks new design
        return <KsaSignin />;
      default:
        return <EtuSignin />;
    }
  }
};
export const getThemeByHostName = (hostName) => {
  const hostNameInfo = hostNamesInfo.find((i) => i.hostName === hostName);
  if (hostNameInfo) {
    //console.log('theme', hostNameInfo.theme, hostNameInfo.hostName)
    return hostNameInfo.theme;
  }
  return false;
};
