import React from 'react';
import { useTranslation } from 'react-i18next';

const SubscribeBtn = ({ btn, loading }) => {
  const { t } = useTranslation();

  return (
    <button
      type="submit"
      id="btn-sub"
      className="btn btn-sub"
      disabled={loading}
    >
      {loading ? (
        <div className="spinner-border text-light spinner-border-sm" role="status">
          <span className="visually-hidden">{t("loading")}</span>
        </div>
      ) : (
        <>
          <img className="btn-bg" src={btn} alt="" />
          <h3 className="btn-txt">{t("subscribe")}</h3>
        </>
      )}
    </button>
  );
};

export default SubscribeBtn;
