import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { verifyPin } from "./../../../Utilies/Utilies/korek-api.js";
import { gtagReportConversion } from "./../../../Utilies/Utilies/helper.js";

import btn from "./../../../assets/imgs/Assetbtn.svg";
import logo from "./../../../assets/imgs/logo2.32a2f0e40fc629cb6e51.png";

import {
  getAntifraud,
  getHeaderEnrichment,
  subscribeRequest,
} from "../../../Utilies/Utilies/korek-api";
import { useTranslation } from "react-i18next";

import Layout from "../../../Components/layout";
import { handlePhoneNumber } from "../../../Utilies/Utilies/helper";
import ErrorMssg from "../../../Components/ErrorMssg/ErrorMssg";
import PhoneInput from "../../../Components/PhoneInput/PhoneInput";
import SubscribeBtn from "../../../Components/SubscribeBtn/SubscribeBtn";

function KorekSignup() {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  let [isPin, setIsPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  let [isVerify, setIsVerify] = useState(false);
  const [searchParams] = useSearchParams();
  let time, transaction;
  // Convert searchParams to an object
  const params = Object.fromEntries(searchParams.entries());

  const [phone, setPhone] = useState("");
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);
  useEffect(() => {
    setMarktingParamsToLocalStorage();
  }, [params]);
  const location = useLocation();
  const pin = "";

  const setScript = async (type) => {
    try {
      const response = await getAntifraud(type, "btn-sub");
      const { script, time, transaction } = response.data;
  
      localStorage.setItem("time", time);
      localStorage.setItem("transaction", transaction);

  
      const headElement = document.querySelector("head");
     
      const newScript = document.createElement("script");
      newScript.id = "antifraud-script";
      newScript.textContent = script;
      headElement.appendChild(newScript);
  
      var event = new Event("DCBProtectRun");
      document.dispatchEvent(event);
  
      console.log("New antifraud script added successfully.");
      
    } catch (error) {
      console.error("Error fetching antifraud script:", error);
    }
  };
  
  const getQueryParams = (url) => {
    const queryString = url.split("?")[1]; // Get the part after '?'
    const params = new URLSearchParams(queryString); // Use URLSearchParams for parsing

    // Convert the params to an object
    const paramsObject = {};
    params.forEach((value, key) => {
      paramsObject[key] = value;
    });

    return paramsObject;
  };
  const handleSubscription = async (phone) => {
    setLoading(true);
    if (!isPin) {
      try {
        const response = await getHeaderEnrichment(time, transaction);
        if (response.success === true) {
          const { url } = response;
          const queryParams = getQueryParams(url);
          console.log(queryParams);
          if (url) {
           setLoading(false);
            window.location.href = url;
          }
        }
      } catch (error) {
        setLoading(false);

        setErrMsg(error.response.data.message);
      }
    } else {
      try {
       setLoading(false);
        let phoneNumber = handlePhoneNumber(phone, 964);
        
        const response = await subscribeRequest(phoneNumber);
        if (response.success === true) {
          localStorage.setItem("phone", phoneNumber);
          console.log("subscribe Response:", response);
                setIsVerify(true);
         /*   setTimeout(() => {
                                 navigate("/korek-verify", { state: phoneNumber });

           }, 5000); */

        
        } else {

          setErrMsg(response.message);
        }

        // if (response.status === 200) {
        //   navigate("/pinCode");
        // } else {
        //   navigate("/fail");
        // }
      } catch (error) {
        setErrMsg(error.response.data.message);
       setLoading(false);

      }
    }
  };
  const setMarktingParamsToLocalStorage = () => {
    const trafficsource = params?.trafficsource;
    const parameter = params?.parameter;
    if (trafficsource) {
      localStorage.setItem("trafficsource", trafficsource);
      localStorage.setItem("parameter", parameter);
    }
  };
  useEffect(() => {
    if (location.pathname === "/proceeding") {
      setIsPin(true);
      setScript("pin");
    } else {
      setScript("he");
    }
  }, [pin]);
 const verifyPincode = async () => {
  setLoading(true);
    const phoneNum = localStorage.getItem("phone");
    const time = localStorage.getItem("time");
    const transaction = localStorage.getItem("transaction");
    const trafficsource = localStorage.getItem("trafficsource");
    const parameter = localStorage.getItem("parameter");

    const params = {
      phone: phoneNum,
      otp: otp,
      transaction: transaction,
      time: time,
      trafficsource,
      parameter,
    };
    try {
      const response = await verifyPin(params);
      if (response.sucess === true) {
        gtagReportConversion();
        navigate("/success");
        setLoading(false);
      } else {
        setErrMsg(response.message);
        setLoading(false);
      }
    } catch (error) {
      setErrMsg(error.response.data.message);
      // navigate("/fail");
      setLoading(false);

    }
  };
  return (
    <>
      <Layout>
        <img className="servImg" src={logo} alt="" />
     
{!isVerify && (
  <>
  
   <h4>{t("SubscribeNowText")}</h4>

      <form
          className="mb-3"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubscription(phone);
          }}
        >
          {isPin &&  (
            <>
              <label htmlFor="phoneInput" className="form-label">
                {t("Enteryourmobilenumber")}
              </label>
              <PhoneInput setPhone={setPhone} />
              {errMsg ? <ErrorMssg errMsg={errMsg} /> : ""}
            </>
          )}
          
          
         <SubscribeBtn btn={btn} loading={loading}  />
        </form> 
  </>
          
)}
       
        {
            isVerify && (
              <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                {t("Enterpincode")}
              </label>
              <input
                type="text"
                className="form-control text-center"
                id="exampleFormControlInput1"
                placeholder="xxxx"
                onChange={(e) => setOtp(e.target.value)}
              />
              {errMsg ? (
                <p className="w-100 overflow-hidden bg-danger-subtle rounded text-danger p-2 mt-3">
                  {errMsg}
                </p>
              ) : (
                ""
              )}
              <button
              id="btn-sub"
                className="btn btn-sub"
                disabled={loading}
                onClick={() => {
                  verifyPincode();
                }}
              >
                <img className={"btn-bg"} src={btn} alt="" />
                <h3 className="btn-txt">{t("confirm")}</h3>
              </button>
                </div>
            )          
        }
        <p>{t("feesText")}</p>
      </Layout>
    </>
  );
}

export default KorekSignup;
