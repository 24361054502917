import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import "./scss/main.css";
import IframeGame from "./Components/IframeGame/IframeGame.jsx";
import NotFound from "./Components/NotFound/NotFound.jsx";
import PaymentContent from "./Components/Payment/PaymentContent.jsx";
import Shopping from "./Components/Shopping/Shopping.jsx";
import WishList from "./Components/WishList/WishList.jsx";
import AuthLayOut from "./LayOuts/AuthLayOut.jsx";
import PaymentLayOut from "./LayOuts/PaymentLayOut.jsx";
import RootLayOut from "./LayOuts/RootLayOut.jsx";
import AndroidGamesPage from "./Pages/AndroidGamesPage/AndroidGamesPage";
import Home from "./Pages/HomePage/Home";
import Html5Games from "./Pages/Html5Games/Html5Games";
import MyTournamentsPage from "./Pages/MyTournamentsPage/MyTournamentsPage";
import OtpCode from "./Pages/OtpCode/OtpCode.jsx";
import PayOrPlay from "./Pages/PayOrPlay/PayOrPlay";
import Profile from "./Pages/Profile/Profile";
import RateUs from "./Pages/RateUs/RateUs.jsx";
import Settings from "./Pages/Settings/Settings";
import Store from "./Pages/Store/Store";
import Success from "./Pages/Success/Success.jsx";
import SuccessMsg from "./Pages/SuccessMsg/SuccessMsg.jsx";
import TheWar from "./Pages/TheWar/TheWar.jsx";
import VoucherGame from "./Pages/Voucher_game/VoucherGame.jsx";
import RequiredAuth from "./Utilies/RequiredAuth.js";
// import MatchMaking from "./Components/MatchMaking/MatchMaking.jsx";
import BattlesComponent from "./Components/BattlesComponent/BattlesComponent.jsx";
import MyTournaments from "./Components/tournamentsSection/MyTournaments.jsx";
import BattlesAndTournsLayOut from "./LayOuts/BattlesAndTournsLayOut.jsx";
import SecondCart from "./Components/SecondCart/SecondCart.jsx";
import DeafultPayment from "./Components/DeafultPayment/DeafultPayment.jsx";
import AndroidGameDetails from "./Pages/GameItemDetails/AndroidGameDetails.jsx";
import HtmlGameDetails from "./Pages/GameItemDetails/HtmlGameDetails.jsx";
import {
  getHostComponentByRoute,
  getHostSigninComponentByRoute,
  getHostSuccessComponentByRoute,
} from "../src/Utilies/RoutesHelper.js";
import Privacy from "./Pages/SignUp/Privacy";
import Terms from "./Pages/SignUp/Terms";
import KorekVerify from "./Pages/SignUp/korek/korek-verify.jsx";
import KsaVerify from "./Pages/SignUp/KsaSignup/KsaVerify.jsx";
import KsaSuccess from "./Pages/SignUp/KsaSignup/KsaSuccess.jsx";
import SuccessKorek from "./Pages/SignUp/korek/korek-success.jsx";
import FailPageKorek from "./Pages/SignUp/korek/korek-fail.jsx";
import PinCodePage from "./Pages/SignUp/zain-iraq/PinCodePage";
import FailPage from "./Pages/SignUp/zain-iraq/FailPage";
import ThankyouPage from "./Pages/SignUp/zain-iraq/ThankyouPage";
import KsaFail from "./Pages/SignUp/KsaSignup/KsaFail.jsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  let hostname = window.location.hostname;
  useEffect(() => {
    let lang = "";

   if(hostname.includes("ziq") || hostname.includes("local") ){
    localStorage.setItem("i18nextLng", "ar");
    lang = localStorage.getItem("i18nextLng");
    console.log("lang",lang);
    i18n.changeLanguage(lang);


   }else{
    localStorage.setItem("i18nextLng", "en");
    let langvar = localStorage.getItem("i18nextLng");
    lang = localStorage.getItem("i18nextLng");
    i18n.changeLanguage(lang);

    if (langvar === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      i18n.changeLanguage(lang);

    }
   }
  }, []);
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <RootLayOut />,
      children: [
        { index: true, element: <Home /> },
        { path: "home", element: <Home /> },
        { path: "payorplay/all", element: <PayOrPlay /> },
        { path: "payorplay/:cat", element: <PayOrPlay /> },
        { path: "store", element: <Store /> },
        { path: "html5games", element: <Html5Games /> },
        { path: "android", element: <AndroidGamesPage /> },
        { path: "androidGameDetails/:gameId", element: <AndroidGameDetails /> },
        { path: "htmlGameDetails/:gameId", element: <HtmlGameDetails /> },
        { path: "rateUs", element: <RateUs /> },
        { path: "successMsg", element: <SuccessMsg /> },
        {
          path: "mytournaments",
          element: (
            <RequiredAuth>
              <MyTournamentsPage />
            </RequiredAuth>
          ),
        },
        {
          path: "battles-tournaments/:sectionType/theWar/:id",
          element: (
            <RequiredAuth>
              <TheWar />
            </RequiredAuth>
          ),
        },
        {
          path: "voucher/:id",
          element: <VoucherGame />,
        },
        {
          path: "settings",
          element: (
            <RequiredAuth>
              <Settings />
            </RequiredAuth>
          ),
        },
        {
          path: "profile",
          element: (
            <RequiredAuth>
              <Profile />
            </RequiredAuth>
          ),
        },
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "/",
      element: <AuthLayOut />,
      children: [
        { index: true, element: getHostSigninComponentByRoute("signIn") },
        { path: "signIn", element: getHostSigninComponentByRoute("signIn") },
        { path: "signup", element: getHostComponentByRoute("signup") },
        { path: "korek-verify", element: <KorekVerify /> },
        { path: "korek-fail", element: <FailPageKorek /> },
        { path: "korek-success", element: <SuccessKorek /> },
        { path: "ksa-verify", element: <KsaVerify /> },
        { path: "ksa-success", element: <KsaSuccess /> },
        { path: "ksa-fail", element: <KsaFail /> },
        { path: "proceeding", element: getHostComponentByRoute("signup") },
        { path: "zain-fail", element: <FailPage /> },
        { path: "zain-verify", element: <PinCodePage /> },
        { path: "otp", element: <OtpCode /> },
        { path: "privacy", element: <Privacy /> },
        { path: "terms", element: <Terms /> },
        { path: "success", element: getHostSuccessComponentByRoute("success") },
        { path: "play/:id", element: <IframeGame /> },
        {
          path: `battles-tournaments/:sectionType/theWar/:tournId/:gameId`,
          element: (
            <IframeGame />
            // <RequiredAuth>
            // 	<MatchMaking />
            // </RequiredAuth>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <BattlesAndTournsLayOut />,
      children: [
        { path: "battles-tournaments/battles", element: <BattlesComponent /> },
        { path: "battles-tournaments/tournamnets", element: <MyTournaments /> },
      ],
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/secondCart",
      element: <SecondCart />,
    },
    {
      path: "/",
      element: <PaymentLayOut />,
      children: [
        {
          path: "defaultPayment",
          element: <DeafultPayment />,
        },
        {
          path: "paymentContent",
          element: (
            <RequiredAuth>
              <PaymentContent />
            </RequiredAuth>
          ),
        },
        {
          path: "wishlist",
          element: (
            <RequiredAuth>
              <WishList />
            </RequiredAuth>
          ),
        },
        {
          path: "shopping",
          element: (
            <RequiredAuth>
              <Shopping />
            </RequiredAuth>
          ),
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
